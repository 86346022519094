header {
    .logo svg {
        // margin-top: 45px;
        width: 211px;
        height: 100%;
        height: 68px;
        fill: $primary-color;
        @include screen-size(mobile-large) {
            width: 140px;
            height: 100%;
            margin-top:-10px;
        }
    }
}
footer {
    .logo svg {
        width: 122px;
        fill: white;
    }
}
