.about {
    @extend .page-container;
    padding: 38px 20px 48px;
    gap: 38px;
    &__collapse-container {
        @include flex($direction: column, $gap: 40px);
        width: 82.5%;
    }
    h3 {
        border-radius: 5px;
        font-size: 24px;
    }
    @include screen-size(desktop-large) {
        padding: 38px 0 48px;
    }
    @include screen-size(mobile-large) {
        padding: 19px 20px 29px;
        gap: 19px;
        &__collapse-container {
            gap: 30px;
            width: 100%;
        }
        h3 {
            font-size: 13px;
        }
    }
}
