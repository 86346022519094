* {
    box-sizing: border-box;
}

.App {
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: $medium;
    min-width: 320px;
}

.page-container {
    @include flex($direction: column, $gap: 43px, $align: center);
    margin: auto;
    max-width: 1240px;
    padding: 50px 20px 0px 20px;
    @include screen-size(desktop-large) {
        padding: 50px 0px 0px 0px;
    }
    @include screen-size(mobile-large) {
        padding: 20px 10px 0px 10px;
        gap: 27px;
    }
}
a {
    text-decoration: none !important ;
}
