.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 3fr));
    row-gap: 50px;
    column-gap: 60px;
    justify-content: center;
    width: 100%;
    max-width: 1240px;
    background-color: $secondary-color;
    padding: 50px;
    margin-bottom: 50px;
    border-radius: 25px;

    .card {
        @include flex();
        position: relative;
        height: 340px;
        padding: 20px;
        border-radius: 10px;
        border-radius: 10px;
        overflow: hidden;
        background-color: $primary-color;
        color: white;
        transition: transform 250ms ease-in-out;
        &:hover {
            cursor: pointer;
            transform: scale(1.01);
        }
        &:hover > .card__thumbnail {
            opacity: 0.2;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.5;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.5) 0%,
                rgba(0, 0, 0, 0.5) 100%
            );
        }
        &__thumbnail {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 250ms ease-in-out;
        }

        h2 {
            position: relative;
            align-self: flex-end;
            z-index: +1;
        }
    }
    @include screen-size(mobile-large) {
        padding: 0;
        margin-bottom: 27px;
        background-color: white;
        row-gap: 20px;
        .card {
            height: 255px;
        }
    }
    @include screen-size(mobile-small) {
        grid-template-columns: repeat(auto-fill, minmax(90%, 3fr));
    }
}
