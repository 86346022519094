.fiche-logement {
    margin: 0 auto;
    width: 100%;
    max-width: 1240px;
    color: $primary-color;
    padding: 50px 20px;
    &__wrapper {
        @include flex($justify: space-between);
        margin-top: 24px;
        margin-bottom: 20px;
    }
    &__title {
        padding-top: 6px;
        font-weight: $medium;
        font-size: 32px;
        line-height: 51px;
    }
    &__location {
        font-size: 18px;
        font-weight: $medium;
        line-height: 26px;
        margin-bottom: 20px;
    }
    &__informations {
        @include flex($direction: column, $justify: space-between);

        &__host {
            @include flex($justify: flex-end, $gap: 10px);
        }
        &__host-avatar {
            height: 64px;
            width: 64px;
            border-radius: 50%;
        }
        &__host-name {
            max-width: 80px;
            align-self: center;
            text-align: right;
        }
        .rating {
            justify-self: flex-end;
        }
    }
    &__collapse-container {
        @include flex($gap: 3.1%, $justify: space-between);
        width: 100%;

        .collapse {
            width: 50%;
        }
    }
    @include screen-size(desktop-large) {
        &__title {
            font-size: 36px;
        }
    }
    @include screen-size(tablet-large) {
        padding: 27px 20px 37px;
        &__title {
            font-size: 27px;
        }
        &__wrapper {
            flex-direction: column;
        }
        &__informations {
            @include flex($direction: row-reverse, $justify: space-between);

            .rating {
                align-self: center;
            }
        }
    }
    @include screen-size(tablet-small) {
    }
    &__title {
        font-size: 22px;
    }
    @include screen-size(mobile-large) {
        &__collapse-container {
            @include flex($direction: column, $gap: 30px);
            .collapse {
                width: 100%;
            }
        }
        &__header {
            margin-top: 0;
        }
        &__wrapper {
            margin-top: 15px;
            margin-bottom: 0;
        }
        &__title {
            padding-top: 0;
            font-size: 18px;
            line-height: 25px;
        }
        &__location {
            font-size: 14px;
            margin-bottom: 5px;
        }
        &__informations {
            &__host {
                margin-top: 16px;
                margin-bottom: 16px;
            }
            &__host-avatar {
                width: 32px;
                height: 32px;
            }
            &__host-name {
                font-size: 12px;
                max-width: 60px;
            }
        }
    }
}
