//colors
$primary-color: #ff6060;
$secondary-color: #f6f6f6;

// Fonts
$regular: 400;
$medium: 500;
$bold: 700;

//loader
$dot-size: 5px;
