.btn-slide {
    @include flex($justify: flex-start);
    width: 96px;
    height: 120px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    // opacity: 0.5;
    // transition: opacity ease-in-out 300ms;
    // &:hover {
    //     opacity: 1;
    // }

    img {
        height: 79px;
    }
    &__prev {
        left: 0;
        padding-left: 23px;
    }

    &__next {
        right: 0;
        padding-right: 23px;
        justify-content: flex-end;
    }
    @include screen-size(mobile-large) {
        padding-top: 1.5px;
        width: 24px;
        height: 24px;
        &__next {
            padding-right: 5px;
        }
        &__prev {
            padding-left: 5px;
        }
        img {
            height: 20px;
        }
    }
}
