.banner {
    @include flex($align: center, $justify: center);
    width: 99.9%;
    height: 223px;
    position: relative;
    border-radius: 25px;
    overflow: hidden;

    h1 {
        font-size: 48px;
        color: white;
        z-index: +1;
    }
    &__homepage:after {
        background: url(../../images/banner-img-1.png);
    }
    &__about:after {
        background: url(../../images/banner-img-2.png);
    }
    &:after {
        content: '';
        position: absolute;
        width: inherit;
        height: inherit;
        background-size: cover;
        background-position: center center;
        z-index: 0;
        filter: brightness(70%);
    }

    @include screen-size(tablet-small) {
        h1 {
            font-size: 36px;
        }
    }
    @include screen-size(mobile-large) {
        &__homepage {
            height: 111px;
            border-radius: 10px;
            h1 {
                margin: 0 16px;
                font-size: 24px;
            }
        }
    }
}
