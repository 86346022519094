
header {
    @include flex($direction: row, $justify: space-between);
    max-width: 1240px;
    height: fit-content;
    width: 100%;
    margin: auto;
    background-color: white;
    position: sticky;
    top: 0px;
    padding: 45px 20px 0 20px;
    // height: calc(45px + 68px);
    z-index: +2;

    @include screen-size(desktop-large) {
        padding: 45px 0 0 0;
    }
    @include screen-size(mobile-large) {
        padding: 20px 10px 0 10px;
    }
}
