.loader {
    @include flex($align: center, $justify: center);
    overflow: hidden;
    width: 100%;
    height: calc((100vh - (113px + 209px)));
    z-index: 100000;

    &__element {
        border-radius: 100%;
        border: $dot-size solid $primary-color;
        margin: calc($dot-size * 2);
    }
    &__element:nth-child(1) {
        animation: preloader 0.6s ease-in-out alternate infinite;
    }
    &__element:nth-child(2) {
        animation: preloader 0.6s ease-in-out alternate 0.2s infinite;
    }
    &__element:nth-child(3) {
        animation: preloader 0.6s ease-in-out alternate 0.4s infinite;
    }
}

@keyframes preloader {
    100% {
        transform: scale(2);
    }
}
