// Flexbox
@mixin flex(
    $direction: initial,
    //row
    $justify: initial,
    //flex-start
    $align: initial,
    //flex-start
    $gap: initial //0
) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
    gap: $gap;
}

//Media Queries - Desktop First
$desktop-large: 1440px;
$tablet-large: 1024px;
$tablet-small: 768px;
$mobile-large: 640px;
$mobile-medium: 425px;
$mobile-small: 375px;

@mixin screen-size($size) {
    @if $size == 'desktop-large' {
        @media (min-width: calc($desktop-large)) {
            @content;
        }
    } @else if $size == 'tablet-large' {
        @media (max-width: calc($tablet-large)) {
            @content;
        }
    } @else if $size == 'tablet-small' {
        @media (max-width: calc($tablet-small)) {
            @content;
        }
    } @else if $size == 'mobile-large' {
        @media (max-width: calc($mobile-large)) {
            @content;
        }
    } @else if $size == 'mobile-medium' {
        @media (max-width: calc($mobile-medium)) {
            @content;
        }
    } @else if $size == 'mobile-small' {
        @media (max-width: calc($mobile-small - 1px)) {
            @content;
        }
    }
}
