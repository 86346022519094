nav {
    @include flex($align: center);
    font-size: 24px;

    ul {
        @include flex($gap: 57px);
    }
    a {
        position: relative;
        display: block;
        text-decoration: none;
        color: $primary-color;
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background: $primary-color;
            transform: scale(0);
            transition: transform 0.2s ease-in-out;
        }
        &:hover::after {
            transform: scale(1);
        }
        &.active {
            border-bottom: 1px solid $primary-color;
            cursor: default;
        }
    }

    @include screen-size(mobile-large) {
        ul {
            gap: 26px;
            margin-top: -10px;
        }
        a {
            font-size: 12px;
            text-transform: uppercase;
        }
    }
}
