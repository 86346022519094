.collapse {
    position: relative;
    // margin-bottom:10px;
    h3 {
        width: 100%;
        position: relative;
        color: white;
        background-color: $primary-color;
        font-size: 18px;
        font-weight: $medium;
        line-height: 54px;
        height: 54px;
        border-radius: 10px;
        padding-left: 10px;
        z-index: +1;
    }
    &__button {
        position: absolute;
        z-index: +2;
        transform: rotate(180deg);
        right: 15px;
        top: 11px;
        cursor: pointer;
        &.active {
            transform: rotate(0deg);
        }
    }
    &__content {
        line-height: 1.426rem;
        font-weight: $regular;
        color: $primary-color;
        background-color: $secondary-color;
        padding-left: 20px;
        padding-top: 35px;
        padding-bottom: 20px;
        padding-right: 28px;

        &-parent {
            margin-top: -10px;
            height: 0px;
            overflow: hidden;
            transition: height ease 0.5s;
            border-bottom: none;
            &.active {
                height: 100px;
            }
        }
    }
    @include screen-size(mobile-large) {
        h3 {
            font-size: 13px;
            height: 30px;
            line-height: 30px;
            border-radius:5px;
        }
        &__button {
            height: 20px;
            top: 4px;
        }
    }
}
