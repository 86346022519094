.page-not-found {
    @include flex($direction: column, $align: center, $gap: 139px);
    padding-top: 139px;
    padding-bottom: 139px;
    color: $primary-color;
    h2 {
        line-height: 263px;
        font-weight: $bold;
        font-size: 263px;
    }
    p {
        font-size: 26px;
        font-weight: 500;
        text-align: center;
    }
    a {
        position: relative;
        color: $primary-color;
        display: inline;
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -3px;
            width: 100%;
            height: 1px;
            background: $primary-color;
            transform: scale(0);
            transition: transform 0.2s ease-in-out;
        }
        &:hover::after {
            transform: scale(1);
        }
    }
    @include screen-size(mobile-large) {
        gap: 113px;
        padding-top: 113px;
        padding-bottom: 113px;
        h2 {
            font-size: 96px;
            line-height: 96px;
        }
        p {
            font-size: 18px;
        }
    }
}
